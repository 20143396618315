import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  PointElement,
  LineElement,
  Tooltip,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, TimeScale, TimeSeriesScale, PointElement,
  LineElement, Tooltip );

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
