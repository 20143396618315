import * as _ from 'lodash';
import { TokenPairs } from './apiManager';

export class TimePeriod {
  static OneWeek = "1W";
  static OneMonth = "1M";
  static ThreeMonths = "3M";
  static SixMonths = "6M";
  static OneYear = "1Y";
  static ThreeYears = "3Y";
  static YearToDate = "YTD";
  static Max = "Max";
}

export function getCurrencySuffix(token) {
  if (token === TokenPairs.BNBUSDT) {
    return ["BNB", "USD"];
  }
  return undefined;
}

var usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function getRewardInFiat(reward, price, prettyPrint = false) {
  let val = reward * price;
  if (prettyPrint) {
    return usdFormatter.format(val);
  }
  return val;
}

export function filterRewardsByTimestamp(data) {
  const output = _(data)
      .groupBy('rewardTime')
      .map((objs, key) => ({
          'rewardTime': key.split('T')[0],
          'reward': _.sumBy(objs, 'reward') }))
      .value();
  return output;
}

// Not sure why, but you can't inline the return statement here.
export function getOneWeekAgo(date) {
  const d = new Date();
  d.setDate(date.getDate() - 7);
  return d;
}

export function getOneMonthAgo(date) {
  const d = new Date();
  d.setMonth(date.getMonth() - 1);
  return d;
}

export function getThreeMonthsAgo(date) {
  const d = new Date();
  d.setMonth(date.getMonth() - 3);
  return d;
}

export function getSixMonthsAgo(date) {
  const d = new Date();
  d.setMonth(date.getMonth() - 6);
  return d;
}

export function getOneYearAgo(date) {
  const d = new Date();
  d.setFullYear(date.getFullYear() - 1);
  return d;
}

export function getThreeYearsAgo(date) {
  const d = new Date();
  d.setFullYear(date.getFullYear() - 3);
  return d;
}

function generateStartingDateString(currentDateString, range) {
  const currentDate = new Date(currentDateString);
  let startingDate;

  switch (range) {
    case (TimePeriod.OneWeek):
      startingDate = getOneWeekAgo(currentDate);
      break;
    case (TimePeriod.OneMonth):
      startingDate = getOneMonthAgo(currentDate);
      break;
    case (TimePeriod.ThreeMonths):
      startingDate = getThreeMonthsAgo(currentDate);
      break;
    case (TimePeriod.SixMonths):
      startingDate = getSixMonthsAgo(currentDate);
      break;
    case (TimePeriod.OneYear):
      startingDate = getOneYearAgo(currentDate);
      break;
    case (TimePeriod.ThreeYears):
      startingDate = getThreeYearsAgo(currentDate);
      break;
    case (TimePeriod.YearToDate):
      return `${currentDate.getFullYear()}-01-01`;
    case (TimePeriod.Max):
    default:
      break;
  }

  return startingDate.toISOString().split('T')[0];
}

export function setDataRange(data, range) {

  if (range === TimePeriod.Max) {
    return data;
  }

  const startingDateString = generateStartingDateString(data[0].rewardTime, range);

  // TODO: Currently if data for a date doesn't exist, it just isn't plotted
  // We should instead put a zero element so it's graphed, makes the UI look better
  return data.filter(element => element.rewardTime >= startingDateString);
}

// Calling this twice on accident.
export function getTotalRewards(cryptoData, range) {
  const filteredData = filterRewardsByTimestamp(cryptoData.rewardDetails);

  let data = filteredData;
  if (range !== TimePeriod.Max) {
    data = setDataRange(filteredData, range);
  }

  return data.map(item => item.reward).reduce((prev, curr) => prev + curr, 0).toFixed(9);
}
