import { Strings } from './resources/content.js';


// TODO: Copy over the terms from https://app.termly.io/dashboard/website/1267955/privacy-policy
export function getPrivacyPolicyBody() {
  return (
    <div className="h-96 overflow-scroll text-slate-500">
      <h1 className="text-lg font-bold">{Strings.Privacy.header}</h1>
      <h1 className="text-md">{Strings.Privacy.date}</h1>
      <br />
      <p>
        Thank you for choosing to be part of our community at <i>Moon Grapher, LLC</i> <b>("Company", "we", "us", or "our")</b>.
        We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice or our practices with regard to your personal information, please contact us at <i>contact@moongrapher.com</i>.
      </p>
      <br />
      <p>This privacy notice describes how we might use your information if you:</p>
      <br />
      <p>&nbsp; • Visit our website at moongrapher.com</p>
      <p>&nbsp; • Engage with us in other related ways ― including any sales, marketing, or events</p>
      <br />
      <p>In this privacy notice, if we refer to:</p>
      <br />
      <p>&nbsp; • <b>"Website"</b>, we are referring to any website of ours that references or links to this policy</p>
      <p>&nbsp; • <b>"Services"</b>, we are referring to our Website, and other related services </p>
      <br />
      <p>
        The purpose of this privacy notice is to explain to you in the clearest way possible
        what information we collect, how we use it, and what rights you have in relation to it.
        If there are any terms in this privacy notice that you do not agree with, please
        discontinue use of our Services immediately.
      </p>
      <br />
      <p>
        Please read this privacy notice carefully, as it will help you understand what we
        do with the information that we collect.
      </p>
      <br />
      <i>
        If you're reading this, our current privacy policy is under development. To receive a copy,
        please contact us at privacy@moongrapher.com. We plan to have our privacy policy posted
        here as soon as possible.
      </i>

    </div>
  );
}
