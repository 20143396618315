export const Strings = {
  Main: {
    title: "Moon Grapher",
    description: "A Visualization Tool for BNB Staking Rewards",
    input: "BNB Wallet Address",
    submit: "Submit",
    data: {
      address: "Wallet Address: ",
      staked: "Total Staked: ",
      rewards: "Total Rewards: ",
      firstRewardDate: "Staking Since: ",
    },
    footer: {
      contribute: "Contribute to this project: ",
      home: "Home",
      information: "Information",
      privacy: "Privacy",
    },
  },
  Privacy: {
      header: "Privacy Policy",
      date: "Last updated February 05, 2022",
      footer: "footer"
  },
  Information: {
      header: "Information",
      body: {
        paragraph1: `Tracking cryptocurrency rewards is difficult. Even with
        all the investment into this ecosystem, there was no user-friendly site
        to see staking performance with just a crypto address.`,
        paragraph2: `Moon Grapher was created with one purpose: to easily
        and privately visualize your staking rewards. Zero cookies, no accounts,
        and a simple-to-use interface. Just enter your address, graph
        your rewads, and download your data.`,
        paragraph3: `We are constantly improving Moon Grapher and are focused
        on performance, mobile-responsiveness, and usability. We hope this
        website becomes a fundamental tool in your daily crypto activities.`,
        paragraph4: `- The Moon Grapher Team`,
        contact: `Contact us at: `,
        email: `contact@moongrapher.com`,
      },
      footer: "footer"
  },
}
