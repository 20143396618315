export function Input(props) {
  return (
    <form onSubmit={props.onSubmit} className="text-slate-500">
      <div className="flex items-center border-b border-cyan-700 py-2">
        <input id="wallet" autoComplete="off" type="text" onChange={e => props.onChange(e.target.value)}
          className="appearance-none bg-transparent border-none w-full text-gray-500 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="BNB Wallet Address" aria-label="Address"/>
        <input className="flex-shrink-2 cursor-pointer bg-cyan-700 hover:bg-cyan-900 border-cyan-700 hover:border-cyan-900 text-sm border-4 text-white py-1 px-5 rounded" type="submit" value="Submit" />
      </div>
    </form>
  );
}
