import { getCurrencySuffix, getRewardInFiat } from './outputHelper';
import { filterRewardsByTimestamp } from './outputHelper';

export function generateRewardDownload(rewardsData, currentPrice, token, date) {
  const data = rewardsData.rewardDetails
  const filteredData = filterRewardsByTimestamp(data);
  // console.log(filteredData);
  const currencySuffix = getCurrencySuffix(token);
  filteredData.forEach((element) => {
    element.token = currencySuffix[0];
    element.fiatReward = `${getRewardInFiat(element.reward, currentPrice)} ${currencySuffix[1]}`;
    element.fiatPrice = `${currentPrice} ${currencySuffix[1]}`;
    element.priceDateTime = new Date().toISOString();
  })
  return filteredData;
}
