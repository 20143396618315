import 'chartjs-adapter-date-fns';
import {
  filterRewardsByTimestamp,
  setDataRange
} from './outputHelper';

export const getOptions = (currentPrice, tokenPair, setCurrentDate, setCurrentReward) => {
  return {
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        offset: true,
        distribution: 'linear',
        display: true,
        ticks: {
          minRotation: 20,
          maxRotation: 30,
          maxTicksLimit: 10,
          padding: 1,
        },
        grid: {
          drawOnChartArea: false,
          drawBorder: true,
          offset: true,
          tickLength: 10,
        },
        type: 'time',
        time: {
          tooltipFormat:'MM/dd/yyyy',
        },
      },
    },
    plugins: {
        tooltip: {
          enabled: false,
        }
      },
      onHover: (evt, activeEls, chart) => {
        if (activeEls.length === 0 || chart.getElementsAtEventForMode(evt, 'nearest', {
            intersect: false
          }, true).length === 0) {
          return;
        }
        activeEls.forEach(point => {
          setCurrentDate(chart.data.labels[point.index]);
          setCurrentReward(chart.data.datasets[point.datasetIndex].data[point.index]);
        })
      }
  };
}

export function createGraphData(cryptoData, tokenPair, range) {
  const data = filterRewardsByTimestamp(cryptoData.rewardDetails);
  const filteredData = setDataRange(data, range);

  // Convert to a more readable date
  const labels = filteredData.map(element => element.rewardTime);
  const graphData = filteredData.map(element => element.reward);
  return {
    labels,
    datasets: [
      {
        data: graphData,
        borderColor: 'rgb(46, 213, 115)',
        backgroundColor: 'rgb(46, 213, 115)',
        pointHoverRadius: 5,
        pointHoverBorderColor: 'rgb(0, 98, 102)',
        pointHoverBackgroundColor: 'rgb(0, 98, 102)',
      }
    ],
  };
}
