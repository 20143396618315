import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import CsvDownload from 'react-json-to-csv';
import {
  getAddressData,
  getCurrentPrice,
  TokenPairs,
} from './apiManager';
import {
  getOptions,
  createGraphData
} from './ChartingHelper';
import {
  generateRewardDownload
} from './DownloadHelper'
import {
  getCurrencySuffix,
  getRewardInFiat,
  getTotalRewards,
  TimePeriod,
  getOneWeekAgo,
  getOneMonthAgo,
  getThreeMonthsAgo,
  getSixMonthsAgo,
  getOneYearAgo,
} from './outputHelper'
import { Input } from './Input.js';
import { Error, ErrorHeaders } from './Error.js';
import { Strings } from './resources/content.js';
import { getPrivacyPolicyBody } from './Privacy';

class Pages {
  static HOME = "HOME";
  static INFORMATION = "INFORMATION";
  static PRIVACY = "PRIVACY";
};

export default function App() {

  let [currentError, setCurrentError] = useState(null);
  let [tempWalletAddress, setTempWalletAddress] = useState(null)
  let [walletAddress, setWalletAdddress] = useState(null);
  let [rewardsData, setRewardsData] = useState(null);
  let [firstRewardDate, setFirstRewardDate] = useState(null);
  let [totalStakedData, setTotalStakedData] = useState(null);
  let [currentPrice, setCurrentPrice] = useState(null);
  let [dashboard, setDashboard] = useState(Pages.HOME);
  let [range, setRange] = useState(TimePeriod.Max);
  let [currentDate, setCurrentDate] = useState(null);
  let [currentReward, setCurrentReward] = useState(null);

  let tokenPair = TokenPairs.BNBUSDT;

  const handleCancel = () => {
    // TODO: Add metric call
    setCurrentError(null);
  }

  const handleHomeClick = () => {
    setDashboard(Pages.HOME);
  }

  const handleInformationClick = () => {
    setDashboard(Pages.INFORMATION);
  }

  const handlePrivacyClick = () => {
    setDashboard(Pages.PRIVACY);
  }

  // Handles "Enter" click on Input object. Will fetch staking rewards.
  const handleSubmit = (event) => {
    if (!tempWalletAddress) {
      setCurrentError(ErrorHeaders.EmptyAddress);
      event.preventDefault();
      return;
    }

    const bnbRegex = new RegExp('^(bnb)([a-z0-9]{39})$');
    if (tempWalletAddress.match(bnbRegex)) {

      if (walletAddress !== tempWalletAddress) {
        setCurrentError(null);
        getAddressData(tempWalletAddress, setRewardsData, setTotalStakedData, setFirstRewardDate, setCurrentError);
        setWalletAdddress(tempWalletAddress);
      }
    } else {
      console.log(`INVALID BNB ADDRESS: ${tempWalletAddress}`);
      // TODO: Add metric call
      setCurrentError(ErrorHeaders.InvalidAddress);
      event.preventDefault();
      return;
    }
    getCurrentPrice(tokenPair, setCurrentPrice, setCurrentError);
    event.preventDefault();
  }

  function getHeader() {
    return (
      <header className="max-w-3xl m-auto text-center">
        <button onClick={handleHomeClick}>
            <h1 className="text-4xl font-bold text-white uppercase">{Strings.Main.title}</h1>
            <h3 className="text-lg text-white tracking-wider" >{Strings.Main.description}</h3>
        </button>
      </header>
    );
  }

  function getRangeButton(period) {
      if (period === range) {
        return <button className="underline font-semibold px-2" onClick={() => setRange(period)}>{period}</button>
      }

      return <button className="px-2" onClick={() => setRange(period)}>{period}</button>
  }

  function getTimePeriodButtons() {
    const today = new Date();

    return (
      <div className="inline-flex" role="group">
        {getRangeButton(TimePeriod.OneWeek)}
        {(firstRewardDate < getOneWeekAgo(today)) && getRangeButton(TimePeriod.OneMonth)}
        {(firstRewardDate < getOneMonthAgo(today)) && getRangeButton(TimePeriod.ThreeMonths)}
        {(firstRewardDate < getThreeMonthsAgo(today)) && getRangeButton(TimePeriod.SixMonths)}
        {(firstRewardDate < getSixMonthsAgo(today)) && getRangeButton(TimePeriod.OneYear)}
        {(firstRewardDate < getOneYearAgo(today)) && getRangeButton(TimePeriod.ThreeYears)}
        {(firstRewardDate.toISOString().split('T')[0] < `${today.getFullYear()}-01-01`) && getRangeButton(TimePeriod.YearToDate)}
        {getRangeButton(TimePeriod.Max)}
      </div>
    );
  }

  function prettyPrintDateLabel(dateLabel) {
    const date = new Date(`${dateLabel}T00:00:00.000`);
    let formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }).format(date);

    return formattedDate;
  }

  function getCursorReward() {
    const reward = currentReward ? currentReward : 0;
    return (
      <div>
        {reward.toFixed(9)} {getCurrencySuffix(tokenPair)[0]} <span className="text-md text-slate-400">({getRewardInFiat(reward, currentPrice, true)})</span>
      </div>
    );
  }

  function getCursorDate() {
    const date = currentDate ? currentDate : new Date().toISOString().split('T')[0];
    return prettyPrintDateLabel(date);
  }

  function getHomeBody() {
    return (
      <div className="text-slate-500">
        {currentError && <Error headerText={currentError} onCancel={handleCancel} />}

        <div className="flow-root">
          {rewardsData &&
            <div className="text-center sm:float-left sm:text-left">
                <h3 className="text-lg font-bold">{getCursorReward()}</h3>
                <h5 className="text-sm">{getCursorDate()}</h5>
            </div>
          }
          <div className="text-center pt-4 sm:pt-0 sm:float-right sm:text-right">
            {firstRewardDate && getTimePeriodButtons()}
          </div>
        </div>
        {rewardsData &&
          <div className="mb-5 text-center">
            <Line className="mt-0" options={getOptions(currentPrice, tokenPair, setCurrentDate, setCurrentReward)} data={createGraphData(rewardsData, tokenPair, range)} />
          </div>
        }

        <Input onSubmit={handleSubmit} onChange={setTempWalletAddress} />
        {rewardsData &&
          <div>
            <div className="text-slate-500 mt-5 mb-5">
              <p className="text-md pt-1 break-words"><b>{Strings.Main.data.address}</b>{walletAddress}</p>
              {range === TimePeriod.Max &&
                <p className="text-md pt-1"><b>{Strings.Main.data.staked}</b>{totalStakedData} {getCurrencySuffix(tokenPair)[0]} <span className="text-md text-slate-400">({getRewardInFiat(totalStakedData, currentPrice, true)})</span></p>
              }
              <p className="text-md pt-1"><b>{Strings.Main.data.rewards}</b>{getTotalRewards(rewardsData, range)} {getCurrencySuffix(tokenPair)[0]} <span className="text-md text-slate-400">({getRewardInFiat(getTotalRewards(rewardsData, range), currentPrice, true)})</span></p>
              {firstRewardDate &&
              <p className="text-md pt-1"><b>{Strings.Main.data.firstRewardDate}</b>{prettyPrintDateLabel(firstRewardDate.toISOString().split('T')[0])}</p>
              }
            </div>
            <div className="text-center mt-5 flex-shrink-2 bg-cyan-700 hover:bg-cyan-900 border-cyan-700 hover:border-cyan-900 border-4 text-white rounded">
              <CsvDownload children={`Download ${getCurrencySuffix(tokenPair)[0]} Rewards Data`} className="p-2 w-full" filename="stakingrewards.csv" data={generateRewardDownload(rewardsData, currentPrice, tokenPair)} />
            </div>
          </div>
        }
      </div>
    );
  }

  function getInformationBody() {
    return (
      <div className="h-96 overflow-scroll text-slate-500">
        <h1 className="text-xl font-bold">{Strings.Information.header}</h1>
        <br />
        <p>{Strings.Information.body.paragraph1}</p>
        <br />
        <p>{Strings.Information.body.paragraph2}</p>
        <br />
        <p>{Strings.Information.body.paragraph3}</p>
        <br />
        <p>{Strings.Information.body.contact}<b>{Strings.Information.body.email}</b></p>
        <br />
        <i>{Strings.Information.body.paragraph4}</i>
      </div>
    );
  }

  function getBody() {
    switch (dashboard) {
      case Pages.INFORMATION:
          return getInformationBody();
      case Pages.PRIVACY:
          return getPrivacyPolicyBody();
      default:
        return getHomeBody();
    }
  }

  function getFooter() {
    return (
      <footer className="max-w-3xl mx-auto">
        {/*
          <div className="text-center mt-8 mb-4">
              <p className="text-white break-words">{Strings.Main.footer.contribute}<b>bnb1ldtgsnuja698w7jwk2khp22hfpaagw7su4fsgx</b></p>
          </div>
        */}
        <div className="flex justify-center text-white">
            <button className="hover:underline" onClick={handleHomeClick}>{Strings.Main.footer.home}</button>
            <span className="mx-3">•</span>
            <button className="hover:underline" onClick={handleInformationClick}>{Strings.Main.footer.information}</button>
            <span className="mx-3">•</span>
            <button className="hover:underline" onClick={handlePrivacyClick}>{Strings.Main.footer.privacy}</button>
        </div>
      </footer>
    );
  }

  return (

    <div className="flex min-h-screen body-bg bg-blue-500 pt-6 md:pt-10 pb-6 px-2 md:px-0">
      <div className="m-auto w-full">
        {getHeader()}
        <main className="m-auto bg-white max-w-3xl max-h-screen p-8 md:p-8 my-5 rounded-lg shadow-2xl overflow-scroll">
          {getBody()}
        </main>
        {getFooter()}
      </div>
    </div>
  );
}

// TODO:
// * Need to customize the charting better:
//   * Vertical lines on cursor
//
// V2: Once we have a backend
//   * We can publish metrics to someplace
//   * Potential metrics: count of each address, page visits, button clicks,
//
//
// Should start to track all daily prices by having a lambda call the
// Binance API once an hour to get BNBUSDT price. For record keeping.
// Store that data in dynamo
// Could backfill past data from: https://www.cryptodatadownload.com/data/binance/


// NEED TO ADD DOCUMENTATION FOR DOWNLOAD CSV

// TODO: The downloaded data should also contain the avg. USD price at that date and the total reward value in USD

// COULD ADD a WEB3.0 Connect button in the top right corner - so that you can easily connect a BNB wallet?

// ADD UNIT TESTS

// Add code splitting and conditional loading of modules


// IDEAS: Save/Favorite an address.
// * Store your favorite addresses in a cookie that we can use to auto fill
//   the graph. Un favorite it too if you'd like.
// * Could have a side bar on the left with a hamburger icon that pops out features or something.
//   Moves the entire graph to the right of the page?
