import { ErrorHeaders } from './Error.js';

// BINANCE DOCS: https://binance-docs.github.io/apidocs/spot/en/
export class TokenPairs {
  static BNBUSDT = "BNBUSDT";
}

export function getAddressData(address, setRewardsData, setTotalStakedData, setFirstRewardDate, setError) {
  Promise.all([
    getStakingRewards(address),
    getTotalStaked(address),
  ])
  .then(data => {
    setRewardsData(data[0]);
    setTotalStakedData(data[1].delegated);

    const lastElement = data[0].rewardDetails[[data[0].total - 1]];
    setFirstRewardDate(new Date(lastElement.rewardTime));
  })
  .catch((error) => {
    console.log(`${error}`);
    setError(ErrorHeaders.InvalidAddress);
  });
}

async function getTotalStaked(address) {
  const response = await fetch(`https://api.binance.org/v1/staking/accounts/${address}/balance`);
  if (!response.ok) {
    throw new Error("Get Total Staked failed.");
  }
  const data = await response.json();
  return data;
}

function createStakingRewardsUrl(address, limit, offset) {
    return `https://api.binance.org/v1/staking/chains/bsc/delegators/${address}/rewards?limit=${limit}&offset=${offset}`;
}


//TODO: If there is no data for a date, we should create the date with a 0 value.
// Currently, if there is no data, it is just ignored and not graphed.
// Makes for a weird UX.
async function getStakingRewards(address) {
  const limit = 100;
  const offset = 0;

  // Make a single call to API to fetch first page
  const initialRequestUrl = createStakingRewardsUrl(address, limit, offset);
  const response = await fetch(initialRequestUrl);
  if (!response.ok) {
    throw new Error("Get Staking Rewards failed.");
  }

  // Return data if no other pages need to be fetched
  const data = await response.json();
  if (data.total <= limit) {
    return data;
  }

  // Fetch rest of data if necessary
  let urls = [];
  for (let offset = limit; offset < data.total; offset = offset + limit) {
    urls.push(createStakingRewardsUrl(address, limit, offset));
  }

  const responseArray = await Promise.all(urls.map(url => fetch(url)));
  const dataArray = await Promise.all(responseArray.map(r => r.json()));
  const finalDataArray = [data].concat(dataArray);

  const rewardDetails = finalDataArray.map(element => element.rewardDetails);
  const rewardData = [[].concat.apply([], rewardDetails)];
  return ({
    total: data.total,
    rewardDetails: rewardData[0]
  });
}


export function getCurrentPrice(token, setCurrentPrice, setCurrentError) {
  fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${token}`)
  .then((response) => response.json())
  .then(data => setCurrentPrice(parseFloat(data.price).toFixed(2)))
  .catch((error) => {
    console.log(`Unable to fetch current USD price of ${token}. ${error}`);
    setCurrentError(`${ErrorHeaders.UnableToFetchPrice} of ${token}`);
  })
}
